import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import RichText from '~components/RichText';
import Container from '~components/Container';
import Link from '~components/Link';
import ThemedVideoCard from '~components/ThemedVideoCard';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import useConversionFramework from '~hooks/useConversionFramework';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getSubscribeButtonProps } from '~utils/buttontext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || '#F6F6F6'
  },
  container: {
    paddingTop: '120px',
    paddingBottom: '87px',
    alignItems: 'center !important',
    gap: '24px !important',
    [theme.breakpoints.down('xs')]: {
      gap: '16px !important',
      paddingTop: '60px',
      paddingBottom: '60px'
    }
  },
  titleContainer: {
    width: '100%',
    marginBottom: 15
  },
  titleRichText: {
    '& > *': {
      color: props => props.text_color || '#000',
      textAlign: 'center',
      fontSize: '48px',
      fontFamily: 'Inter',
      fontWeight: 600,
      lineHeight: '113%',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        lineHeight: '117%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      margin: 'auto'
    }
  },
  playlistContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    maxWidth: '1066px',
    paddingBottom: '16px',
    [theme.breakpoints.down('xs')]: {
      gap: '8px'
    }
  },
  showMoreButton: {
    textAlign: 'center',
    color: props => props.text_color || '#000',
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: '600',
    lineHeight: '22px',
    borderRadius: '41px',
    border: props => (props.text_color ? `1px ${props.text_color} solid` : '1px black solid'),
    backgroundColor: 'transparent',
    padding: '11px 27px',
    cursor: 'pointer',
    alignSelf: 'flex-start',
    marginTop: '24px',

    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '16px',
      padding: '4px 12px',
      marginTop: '12px'
    }
  }
}));

const MarketingLandingVideoList = ({
  playlists,
  title_video_list,
  see_more_button_text,
  see_more_path,
  background_color,
  text_color
}) => {
  const classes = useStyles({ background_color, text_color });

  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const {
    text: subscribeBtnText,
    url: subscribeBtnUrl,
    shouldTrackAnalytics: shouldCtaButtonTrackAnalytics,
    analyticsEvent: ctaAnalyticsEvent
  } = getSubscribeButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.titleContainer}>
          <RichText
            id="videoListTitle"
            html={title_video_list?.html}
            externalClassName={classes.titleRichText}
            verticalSpacing={0}
          />
        </div>

        <div className={classes.playlistContainer}>
          {playlists &&
            playlists.map((playlistToShow, index) => (
              <React.Fragment key={`${playlistToShow.url} ${index + 2}`}>
                <ThemedVideoCard playlist={playlistToShow} showLabel={false} />
              </React.Fragment>
            ))}
          {see_more_path && !isMobile && (
            <Link className={classes.showMoreButton} to={see_more_path} underline="none">
              {see_more_button_text}
            </Link>
          )}
        </div>

        <div className={classes.buttonArea}>
          <RoundedLinkButton
            text={isMobile ? see_more_button_text : subscribeBtnText}
            to={isMobile ? see_more_path : subscribeBtnUrl}
            shouldTrackAnalytics={!isMobile && shouldCtaButtonTrackAnalytics}
            analyticsEvent={!isMobile && ctaAnalyticsEvent}
            analyticsTitle={!isMobile && title_video_list?.text}
            analyticsSection="Video List"
            size="small"
            variant="blue"
          />
        </div>
      </Container>
    </div>
  );
};

MarketingLandingVideoList.propTypes = {
  title_video_list: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  see_more_button_text: PropTypes.string,
  see_more_path: PropTypes.string,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        html: PropTypes.string
      }),
      label_is_new: PropTypes.string,
      label_is_audio: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

MarketingLandingVideoList.defaultProps = {
  see_more_path: '',
  see_more_button_text: '',
  background_color: '#F6F6F6',
  text_color: '#000000'
};

export default MarketingLandingVideoList;
